import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import lavaLlamaScreenshotWide from '../images/lava-llama-screenshot-wide.png'
import lavaLlamaScreenshotNarrow from '../images/lava-llama-screenshot-narrow.png'
import llamaRunScreenshot from '../images/llama-run-screenshot.png'
import llamaJumpScreenshot from '../images/llama-jump-screenshot.png'
import llamaFireDangerScreenshot from '../images/llama-fire-danger-screenshot.png'
import wngLogo from '../images/wng-logo.png'
import ContactForm from '../components/ContactForm';
import Header from '../components/Header';
import Footer from '../components/Footer';

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Lava Llama</title>
      </Helmet>

      <Header anchorLinks />
      <main id="main-content">

        <h1 className="sr-only">Lava Llama</h1>

        <div id="home-hero" className="position-relative overflow-hidden text-center bg-light mb-3 mb-md-5">
          <img alt="Llama Llama Screenshot (Wide)" src={lavaLlamaScreenshotWide} className="w-100 d-none d-lg-block" />
          <img alt="Llama Llama Screenshot" src={lavaLlamaScreenshotNarrow} className="w-100 d-lg-none" />
          <div id="app-store-download-buttons" className="pt-3 pt-md-0 px-3">
            <a className="btn btn-llama w-100 w-md-auto mb-1 mb-md-0 me-md-3" href="https://itunes.apple.com/us/app/lava-llama/id1131968402" title="Download on Apple / iOS App Store">Free Download for iOS &raquo;</a>
            <a className="btn btn-llama w-100 w-md-auto mt-1 mt-md-0 ms-md-3" href="https://play.google.com/store/apps/details?id=com.wednesdaynightgames.lavallama" title="Download on Android /Google Play Store">Free Download for Android &raquo;</a>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-4 text-center">
              <img className="rounded-circle mb-3" src={llamaRunScreenshot} alt="Lava LLama Game Running Screenshot" width="140" height="140" />

              <h2>Run like your life depends on it!</h2>
              <p>Enter into an <em>8-bit</em> world with exciting <em>8-bit</em> graphics and immersive <em>8-bit</em> music as you guide your llama to <em>8-bit</em> safety.</p>
            </div>
            <div className="col-lg-4 text-center">
              <img className="rounded-circle mb-3" src={llamaJumpScreenshot} alt="Lava LLama Game Jumping Screenshot" width="140" height="140" />

              <h2>Dodge interesting and varied obstacles!</h2>
              <p>Discover new zones at altitudes high and low! Watch out for pineapples, pigeons and more!</p>
            </div>
            <div className="col-lg-4 text-center">
              <img className="rounded-circle mb-3" src={llamaFireDangerScreenshot} alt="Lava Llama Game Dangerous Fire Screenshot" width="140" height="140" />

              <h2>Stay alive for as long as you can!</h2>
              <p>What caused this massive volcanic eruption anyway? How far will the lava go? Don't question the premise &mdash; JUST STAY ALIVE!</p>
            </div>
          </div>

          {/* START THE FEATURETTES */}

          <hr id="news" className="featurette-divider my-5" />

          <div className="row featurette py-3">
            <div className="col-lg-7">
              <h2 className="featurette-heading">News</h2>
              <p className="lead">Check out our gameplay trailer!</p>
              <p>
                <a className="btn btn-primary mb-3 me-3" href="https://itunes.apple.com/us/app/lava-llama/id1131968402">Download for iOS &raquo;</a>
                <a className="btn btn-primary mb-3" href="https://play.google.com/store/apps/details?id=com.wednesdaynightgames.lavallama">Download for Android &raquo;</a>
              </p>
            </div>
            <div className="col-lg-5">
              <div className="ratio ratio-16x9">
                <iframe title="Lava Llama gameplay trailer video" className="border img-thumbnail" width="560" height="315" src="https://www.youtube-nocookie.com/embed/x6oIhmqM7R0?autoplay=0" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>
          </div>

          <hr id="about" className="featurette-divider my-5" />

          <div className="row featurette py-3">
            <div className="col-lg-7">
              <h2 className="featurette-heading">About</h2>
              <p className="lead">Lava Llama was made by a team of friends who have a passion for developing games. They have been involved in a variety of game development projects including Lava Llama (available now for iOS and Android), The Adventures of Dude Man, and Project Shadow. Will they make more games in the future? Maybe! For now though, all effort is being channelled into improving and promoting Lava Llama.</p>
            </div>
            <div className="col-lg-5">
              <img className="featurette-image img-fluid mx-auto img-thumbnail" src={wngLogo} alt="Wednesday Night Games About Us" />
            </div>
          </div>

          <hr id="contact" className="featurette-divider my-5" />

          <div className="row featurette py-3">
            <div className="col-lg-7">
              <h2 className="featurette-heading">Contact</h2>
              <p className="lead">Feel free to send us questions and feedback!</p>
            </div>
            <div className="col-lg-5">
              <ContactForm source="www.lavallama.com" />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </Layout >
  )
};

export default IndexPage;
